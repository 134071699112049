<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="show"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="choose-selectedPlan-checkCode"
  >
    <div class="main">
      <div class="phone">
        <span v-for="(item,index) in list" :key="index">{{item.phone}}{{index!==list.length?',':''}}</span>
      </div>
      <el-form :inline="true" :model="form.data">
        <el-form-item label="联系人">
          <el-input v-model="form.data.user" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="form.data.user" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="发送次数">
          <el-select v-model="form.data.user" placeholder="请选择发送次数">
            <el-option label="第一次发送" value="0"></el-option>
            <el-option label="第二次发送" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  // 查看二维码
  name: 'checkCode',
  props: {
    title: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      show: false,
      list: [],
      form: {
        data: {
          user: '',
        },
      },
    };
  },
  methods: {
    toggle(show) {
      this.show = show;
    },
    onSubmit() {},
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    .phone {
      line-height: 1.5;
    }
    .el-form {
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
